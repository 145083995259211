
import {
  Vue, Component, Prop,
} from 'vue-property-decorator'
import Organisation from '@/components/atoms/Organisation.vue'

@Component({
  components: {
    Organisation,
  },
})
export default class Organisations extends Vue {
  @Prop({ type: Array, default: () => undefined }) private organisationsList!: Array<any>
}
