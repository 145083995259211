

import {
  Component, Vue, Prop, Watch,
} from 'vue-property-decorator'
import { parse } from 'node-html-parser'
import _ from 'lodash'

import { l, localeFromPath } from '@/utils/helpers'
import store from '@/store'

@Component({
})
export default class TableOfContents extends Vue {
  @Prop() private contents!: string

  constructor() {
    super()
    this.tableOfContentsHTML = ''
  }

  private tableOfContentsHTML: string

  @Watch('contents')
  onContentChange() {
    this.fetchHtml()
  }

  @Watch('cardObject')
  onContentsChange(newContents: any, oldContents: any) {
    this.contents = newContents
    this.fetchHtml()
  }

  @Watch('$route', { immediate: true, deep: true })
  fetchHtml() {
    /* Create a table of content based on H2
     * headers in the contents field
     */
    if (this.contents) {
      this.tableOfContentsHTML = ''
      /* eslint-disable prefer-destructuring */
      let contents = this.contents
      /* Get the content HTML trough the parser
       * so that we can loop trough headers
       */
      const contentsHTML = parse(contents)
      const headers = contentsHTML.querySelectorAll('h2')

      /* Loop trough headers and build
       * table of content HTML if
       * there are at least two headings
       */
      if (headers.length > 1) {
        this.tableOfContentsHTML = `<h4 class="mt2 pv2">${l('tableOfContents', localeFromPath(this.$route.path))}:</h4>`
        this.tableOfContentsHTML += '<ul class="list pl0 mv2">'
        _.forEach(headers, (h, i) => {
          // Add table of content HTML with onclick event attribute
          this.tableOfContentsHTML
            += `<li class="list pl0 mv2"><a class="pointer no-underline b eti-red small toc-font source-sans-pro" onclick="window.scrollTo(0, document.getElementById(${i}).offsetTop)">${h.text}</a></li>`
          /* Insert IDs into headers in content as
           * jump to anchors for the onclick events
           */
          contents = contents.replace(
            `<h2>${h.text}`, `<h2 id="${i}">${h.text}`,
          )
        })
        this.tableOfContentsHTML += '</ul>'
      }
      // Put contents field with modified headers back into the store
      try {
        store.dispatch('set', { key: 'active.post.post.contents', data: contents })
      } catch (err) {
        //
      }
    }
  }
}
