
import {
  Vue, Component, Prop,
} from 'vue-property-decorator'
import Person from '@/components/atoms/Person.vue'

@Component({
  components: {
    Person,
  },
})
export default class Persons extends Vue {
  @Prop({ type: Array, default: () => undefined }) private personsList!: Array<any>
}
