
import {
  Vue, Component, Prop, Watch,
} from 'vue-property-decorator'
import _ from 'lodash'
import store from '@/store'

@Component({
  data: () => ({
    organisation: {},
    role: {},
  }),
})
export default class Organisation extends Vue {
  @Prop() private organisationID!: string

  @Prop() private roleID!: string

  private organisation!: any

  private role!: any

  @Watch('organisationId')
  onPersonIdChange(newOrganisationId: string, oldOrganisationId: string) {
    this.organisation = this.$store.getters.organisation[newOrganisationId]
  }

  @Watch('roleId')
  onRoleIdChange(newRoleId: string, oldRoleId: string) {
    this.role = this.$store.getters.role[newRoleId]
  }

  @Watch('$route', { immediate: true, deep: true })
  onUrlChange() {
    // Fetch from the API if a UUID is passed as props and if no cardObject prop is present
    if (this.organisationID) {
      store.dispatch(
        'fetch',
        `/?uuid=${this.organisationID}`,
      )
        .then((result: any) => {
          if (result) {
            // Set the content in the VUEX store with its uuid as key
            try {
              store.dispatch('set', { key: `active.organisation.${this.organisationID}`, data: result })
              // Update render
              this.$nextTick(() => {
                this.organisation = this.$store.getters.organisation[this.organisationID]
              })
            } catch (err) {
              // console.log(err)
            }
          }
        })
        .then(() => {
          if (this.roleID) {
            store.dispatch(
              'fetch',
              `/?uuid=${this.roleID}`,
            )
              .then((result: any) => {
                if (result) {
                  // Set the content in the VUEX store with its uuid as key
                  try {
                    store.dispatch('set', { key: `active.role.${this.roleID}`, data: result })
                    // Update render
                    this.$nextTick(() => {
                      this.role = this.$store.getters.role[this.roleID]
                    })
                  } catch (err) {
                    // console.log(err)
                  }
                }
              })
          }
        })
        .catch(
          (error: string) => { throw new Error(error) },
        )
    }
  }
}
