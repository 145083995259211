

import {
  Component, Prop, Vue,
} from 'vue-property-decorator'


import SvgIcon from '@/components/atoms/SvgIcon.vue'

@Component({
  components: {
    SvgIcon,
  },
})
export default class ScrollUp extends Vue {
    isVisible: boolean = false

    mounted() {
      window.addEventListener('scroll', this.scrollListener)
    }

    beforeDestroy() {
      window.removeEventListener('scroll', this.scrollListener)
    }

    scrollListener(e:any) {
      this.isVisible = window.scrollY > 1230
    }
}
